import {
  InitAppForPageFn,
  IWixAPI,
  ViewerScriptFlowAPI,
} from '@wix/yoshi-flow-editor';
import { MEMBERS_AREA_V2 } from '@wix/app-definition-ids';

import { initializeMonitoring, toMonitored } from './utils/monitoring';
import { initAppDataStore } from './services/app-data-store';
import {
  createPublicAPI as currentMACreatePublicAPI,
  initApplication,
} from './current-ma';
import {
  createPublicAPI as maV2CreatePublicAPI,
  getGlobalControllerConfig,
} from './ma-v2';
import { Experiment } from './constants';

let _wixCodeApi: IWixAPI | null = null;
let _flowApi: ViewerScriptFlowAPI | null = null;

const getIsMembersAreaV2 = () => {
  const shouldCheckIsMaV2ByInstalledSection = _flowApi?.experiments.enabled(
    Experiment.CheckIsMaV2ByInstalledSection,
  );

  if (shouldCheckIsMaV2ByInstalledSection) {
    return _wixCodeApi?.site.isAppSectionInstalled({
      sectionId: 'member_page',
      appDefinitionId: MEMBERS_AREA_V2,
    });
  }

  const controllerConfig = getGlobalControllerConfig();
  return controllerConfig?.config.isMembersAreaV2;
};

const getViewerPlatformExports = async () => {
  const isMembersAreaV2 = !!(await getIsMembersAreaV2());

  return isMembersAreaV2 ? maV2CreatePublicAPI() : currentMACreatePublicAPI();
};

export const initAppForPage: InitAppForPageFn = async (
  initParams,
  platformApis,
  wixCodeApi,
  platformServices,
  flowApi,
) => {
  initializeMonitoring(initParams, platformServices);
  _wixCodeApi = wixCodeApi;
  _flowApi = flowApi;

  const { httpClient } = flowApi;
  const appDataStore = initAppDataStore(initParams, wixCodeApi, flowApi);
  const initApplicationPromise = toMonitored('initAppForPage', () =>
    initApplication(
      initParams as any,
      platformApis as any,
      wixCodeApi,
      httpClient,
      flowApi,
      platformServices,
    ),
  )();

  return {
    initApplication: async () => {
      await initApplicationPromise;
      return appDataStore.getAppData();
    },
  };
};

export const exports = () => {
  return getViewerPlatformExports();
};
